import React, { useEffect } from 'react'
import Layout from '../components/layout.tr'
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Counter from '../components/counter'
import AgileAnim from '../components/agileAnim.tr'
import Subscribe from '../components/subscribe'
import MemberCard from '../components/memberCard'
import PageHeader from '../components/pageHeader'
import members from "../data/team-members.json"
import LinkToForm from '../components/linkToForm.tr'
import ContactUsForm from '../components/contact-us.tr'
import servicesTopImage from "../images/services-top-image.jpg"
import moodlyLogo from "../images/moodly.svg"
import consulting from "../images/consulting.svg"
import development from "../images/development.svg"
import learning from "../images/learning.svg"

import $ from "jquery";
require('jquery-inview');
const anime = require('animejs').default;
require("@lottiefiles/lottie-player");

export default () => {
  useEffect(() => {

    // Fade Up 5 (Footer blocks)
    var fadeUp5 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp5
      .add({
        targets: ['.centered-h2', '.w-layout-grid'],
        translateY: [50, 0],
        translateZ: 0,
        opacity: [1, 1],
        easing: "easeOutExpo",
        duration: 900,
        delay: (el, i) => 50 + 80 * i
      });


    // Fade Up 5 (Product Preview)
    var fadeUp6 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp6
      .add({
        targets: ['.hiw-hero-heading', '.hero-paragraph', '.hiw-img'],
        translateY: [25, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1200,
        delay: (el, i) => 50 + 80 * i
      });



    // Play animation when scrolled into view
    $('.w-layout-grid').on('inview', function (event, isInView) {
      if (isInView) {
        fadeUp5.play();
        fadeUp5.restart();
      } else {
      }
    });

    // Play animation when scrolled into view
    $('.hiw-hero').on('inview', function (event, isInView) {
      if (isInView) {
        fadeUp6.play();
        fadeUp6.restart();
      } else {
      }
    });

  });
  return (
    <Layout currentPath='/tr/hakkimizda/' light={true} fullPage={true}>
      <SEO title="Hakkımızda" />
      <div class="hiw-hero wf-section">
        <div class="hiw-container">
          <div class="hiw-left">
            <h1 class="hiw-hero-heading simple-fade-in" ><span className="text-span-2">Dijital dönüşüm</span> sürecinizde yanınızdayız </h1>
            <p class="hero-paragraph simple-fade-in" >Dijital dönüşüm hızına ayak uydurmak isteyen işletmelerin, mevcut ve olası ihtiyaçlarını ve sektörün gerekliliklerini karşılaması önem taşıyor. Uzman proje ekiplerimiz, sunduğu danışmanlık & destek hizmetiyle çözümlerin ihtiyaçlara göre uyarlanması ve kurgulanmasını sağlar.</p>
          </div>
          <img src={servicesTopImage} loading="lazy" alt="" class="hiw-img" />
          <img src="https://uploads-ssl.webflow.com/60590851dbb9ac7f8483c542/60700a928118414537c9ce90_02_top-shape.svg" loading="lazy" class="hiw-top-shape" />
        </div>
      </div>
      <div class="_4-split">
        <h2 class="centered-h2">Hizmetlerimiz</h2>
        <div class="w-layout-grid _4-split-grid">
          <div class="_4-split-container right-line">
            <div class="_4-split-img-con">
              <img src={moodlyLogo} loading="lazy" alt="" class="_4-split-img _1" />
            </div>
            <div class="_4-split-text-container">
              <h3 class="_4-split-title">Kurum içi iletişim portalı</h3>
              <p class="_4-split-paragraph">Tüm iş süreçlerinizi dijitalleştiren, çalışanlarınıza şirket kültürünü sevdiren ve iletişim süreçlerini tek bir merkezden yürütmenizi sağlayan akıllı kurum içi iletişim portalıdır. 35+ modül ile portalınıza ekleyeceğiz modülleri hızlıca kullanmaya başlayabilir, insan kaynakları süreçlerinizi, dosya, belge veya fotoğraflarınıza hızlıca erişim sağlayabilirsiniz.
              <br /><br />Aynı zamanda personellerinizin verimliliğini ölçmek için geliştirilen oyunlaştırma uygulamaları, çalışan memnuniyet anketi, günlük ruh hali belirtimi gibi modüller ile personelinizin mutluluğunu ölçümleyebilirsiniz.</p>
            </div>
          </div>
          <div class="_4-split-container">
            <div class="_4-split-img-con">
              <img src={moodlyLogo} loading="lazy" alt="" class="_4-split-img _1" />
            </div>
            <div class="_4-split-text-container">
              <h3 class="_4-split-title">Personel mutluluk analizi</h3>
              <p class="_4-split-paragraph">"Şirketlerin en büyük yatırımları çalışanlarıdır" düşüncesiyle kurum içi platformumuzdan ve üçüncü taraf uygulamalarınızdaki verileri yapay zeka ile destekleyerek; personellerinizin mutluluk oranını tahminlemeyi hedefleyen çözümümüzdür.
              <br /><br />Personellerinizin mutluluk oranı ile işten ayrılma durumu olan veya kendini geliştirme potansiyeline sahip personellerinizi önceden görebilmek artık daha mümkün.</p>
            </div>
          </div>
          <div class="_4-split-container top-line right-line">
            <div class="_4-split-img-con">
              <img src={moodlyLogo} loading="lazy" alt="" class="_4-split-img _1" />
            </div>
            <div class="_4-split-text-container">
              <h3 class="_4-split-title">ChatBot</h3>
              <p class="_4-split-paragraph">Dijitalleşme stratejisi kapsamında kurumlar süreçlerinde self-servis oranını arttırmayı hedeflemektedir. Tedarikçi, bayi, müşteri, son kullanıcı ve iç süreçlerindeki operasyonel işlemleri gerçekleştirebilecek akıllı asistan – ChatBot ’lara ihtiyaç duymaktadır. <br /><br />
                Moodly ChatBot platformu, kod yazmadan kendi süreçlerinizi tasarlayarak tüm platformlar üzerinden ulaşılabilir olmanızı sağlar.<br /><br />

              </p>
            </div>
          </div>
          <div class="_4-split-container top-line">
            <div class="_4-split-img-con">
              <img src={development}
                loading="lazy" alt="" class="_4-split-img _3" />
            </div>
            <div class="_4-split-text-container">
              <h3 class="_4-split-title">Danışmanlık</h3>
              <p class="_4-split-paragraph">Alanında uzman kadromuz ile Office 365, SharePoint, PowerApps, Power Automate, Microsoft 365 ve Power BI konularında süreç çözümleri ile verimliliğinizi arttırın.</p>
            </div>
          </div>
          <div class="_4-split-container top-line right-line">
            <div class="_4-split-img-con">
              <img src={learning}
                loading="lazy" alt="" class="_4-split-img _4" />
            </div>
            <div class="_4-split-text-container">
              <h3 class="_4-split-title">Eğitim</h3>
              <p class="_4-split-paragraph">Online veya yerinde eğitim ile şirketinize ve seviyelere özel sınıflarda, bire bir uygulamalı Office 365, SharePoint, PowerApps, Power Automate, Microsoft 365 ve Power BI eğitimleri ile uzmanlaşma şansı yakalayın.</p>
            </div>
          </div>
          <div class="_4-split-container top-line">
            <div class="_4-split-img-con">
              <img src={consulting}
                loading="lazy" alt="" class="_4-split-img _5" />
            </div>
            <div class="_4-split-text-container">
              <h3 class="_4-split-title">Yazılım Geliştirme</h3>
              <p class="_4-split-paragraph">Microsoft Azure servisleri, kurumsal iş zekası raporlama, SharePoint, Web & Mobil uygulama ve .NET çözümleriniz için Fiboo güvenilir iş ortağınızdır.</p>
            </div>
          </div>
        </div>
      </div>

      <ContactUsForm />
    </Layout>
  );
}