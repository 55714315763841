import React from 'react'

export default class AgileAnim extends React.Component {
  componentDidMount(){
    let logo = document.getElementById('simLogo');
    let animateLogo = function(ev){
      let logoHeight = parseInt( window.getComputedStyle(logo, null).getPropertyValue("height"));

      if( (logo.parentNode.offsetTop) - (logoHeight / 2) < window.pageYOffset ){
        logo.classList.add('animated');
      }

    }
    window.addEventListener('scroll', animateLogo);
  }
    
  render(){
  return (
    <div className="container agile-container">
      <div className="agile-anim">
        <div className="sim-logo" id="simLogo">
          <div className="lh1"></div>
          <div className="lh2">
            <div className="lh2p">
              <div className="lh2p">
                <div className="lh2p">
                  <div className="lh2p">
                    <div className="lh2p">
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content1">
            <div className="smch">AGILE</div>
            <div className="smcm">Metodoloji</div>
            <div className="smcs">fiboo</div>
          </div>
          <div className="content2">
            <div className="d">
              <b>Müzakere</b>
              <span>Ürün sahibi satış ekibiyle tanışır</span>
            </div>
            <div className="a">
              <b>Analiz</b>
              <span>Gereksinimler analiz edilir</span>
            </div>
            <div className="s">
              <b>Strateji</b>
              <span>Stratejiler belirlenir</span>
            </div>
            <div className="e">
              <b>Uygulama</b>
              <span>Plan uygulanır</span>
            </div>
            <div className="q">
              <b>Kalite Güvence Testi</b>
              <span>Kalite kontrol sağlanır</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
}